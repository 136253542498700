import './css/login.css';

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LogoHotExpress from './assets/HotExpress_Logo.jpg'
import LogoUnoBox from './assets/logoUnoBox.jpg'
import {useHistory} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const Login = ({SetDataUser})=>{
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxHeight: '85%',
        overflow: 'scroll',
        maxWidth: '400px',
        minWidth: '260px',
        border: '2px solid #7294c3',
        borderRadius: '10px',
        color:'#7294c3',
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        SetNewPass({passwordNew:"",passwordNew2:""})
    };

    let history = useHistory()
    function isMoreThan30Minutes(date1, date2) {
        const diff = Math.abs(date2 - date1);
        const minutesDiff = diff / (1000 * 60); 
            return minutesDiff > 30;
    }
    const lastSesion = localStorage.getItem('lastSesion');
    const dataUser = localStorage.getItem('dataUser'); 
    if (lastSesion && dataUser) {
        const now = new Date();
        if (isMoreThan30Minutes(new Date(lastSesion), now)) {
            //borrar los datos de localStorage
            localStorage.removeItem('dataUser');
            localStorage.removeItem('lastSesion');
        } else {
            //history.push("/traking")
        }
    } else {
      // No existen los datos en localStorageHB-4954
    }
    const [dataLogin, SetDataLogin] = useState({user:"",password:""})
    const [newPass,SetNewPass] = useState({passwordNew:"",passwordNew2:""})
    const [statusLogin,SetStatusLogin] = useState(false)
    const checkPass = () => {
        console.log(dataLogin);
    
        if (dataLogin.user === "" || dataLogin.password === "") {
        SetStatusLogin({
            Estatus: "false",
            Mensaje: "El Usuario y la contraseña no pueden estar vacios",
        });
        return false;
        }
    
        var requestOptions = {
        method: "GET",
        redirect: "follow",
        };
    
        fetch(
        `https://rolando.weorderit.com/datasnap/rest/TApiHotExpress/LoginCliente/${dataLogin.user}/${dataLogin.password}`,
        requestOptions
        )
        .then((response) => response.json())
        .then((result) => {
            const infoLogin = result.result[0].Login[0];
            console.log(infoLogin);
            SetDataUser(infoLogin);
            if(infoLogin.Estatus === "CreaContrasena"){
                handleOpen(true)
            }
            if (infoLogin.Estatus === "true") {
            SetStatusLogin({
                Estatus: "true",
                Mensaje: "",
            });
            history.push("/traking")
            } else {
            SetStatusLogin({
                Estatus: "false",
                Mensaje: "El Usuario o la contraseña no son correctos",
            });
            return false;
            }
        })
        .catch((error) => console.log("error", error));
    };
    const handleDataLogin = (e,type)=>{
        //console.log(e.target.value)
        if(type === "user"){
            SetDataLogin({
                ...dataLogin,
                user:e.target.value
            })
        }
        if(type === "password"){
            SetDataLogin({
                ...dataLogin,
                password:e.target.value
            })
        }
        SetStatusNewPass(null)
    }
    const handleEnterPress = (ev)=>{
        if (ev.key === 'Enter') {
            ev.preventDefault();
            checkPass();
        }
    }
    const handleNewPass = (ev, name) => {
        SetNewPass({
            ...newPass,
            [name]:ev.target.value
        });
        SetStatusNewPass(null)
    }
    const [statusNewPass,SetStatusNewPass]=useState(null)
    const saveNewPassword = ()=>{
        if(newPass.passwordNew === newPass.passwordNew2){
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            };
            fetch(
            `https://rolando.weorderit.com/datasnap/rest/TApiHotExpress/CreaContrasena/${dataLogin.user}/${newPass.passwordNew}/${newPass.passwordNew2}`,
            requestOptions
            )
            .then((response) => response.json())
            .then((result) => {
                const infoNewPass = result.result[0].Contrasena[0];
                console.log("infoNewPass",infoNewPass)
                if(infoNewPass.Estatus === "true"){
                    handleClose();
                    SetStatusNewPass(infoNewPass)
                    SetDataLogin({
                        ...dataLogin,
                        password:""
                    })
                }
            })
        }else{
            SetStatusNewPass({
                Estatus:"false",
                Mensaje:"Las contraseñas no coinciden"
            })
        }
    }
    const MarcaDominio = window.location.href;
    let marcaLogo = LogoHotExpress
    if(MarcaDominio.includes('1boxpanama') === true){
        marcaLogo = LogoUnoBox
    }

    return(
        <div className="container">
        <div className="screen">
            <div className="screen__content">
                <div className="login">
                    <img alt='logo' className="imgLogo" src={marcaLogo} />
                    <Box
                    component="form" sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }} noValidate  autoComplete="off" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <TextField id="standard-user" label="Número PIN ó correo eléctronico" variant="standard" value={dataLogin.user}  onChange={(e)=>{handleDataLogin(e,"user")}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                        <TextField id="standard-pass" label="Contraseña" type ="password" variant="standard" value={dataLogin.password} onChange={(e)=>{handleDataLogin(e,"password")}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                    <Button className=" login__submit"  onClick={()=>checkPass()}>Ingresar</Button>
                    </Box>
                    
                    {statusNewPass?
                        statusNewPass.Estatus === "true"?
                        <Alert className='alerta' variant="outlined" severity="success">
                            {statusNewPass.Mensaje||"datos modificados correctamente"}
                        </Alert>:null:statusLogin?
                        statusLogin.Estatus === "false"?<Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert className='alerta' variant="outlined" severity="error">
                            {statusLogin.Mensaje||"El Usuario o la contraseña no son correctos"}
                        </Alert>
                    </Stack>:false
                    :false}
                </div>
                <div style={{padding:'0px 30px', display:'flex', justifyContent:'center'}}>
                    <Button onClick={()=>history.push('/register')}>Afiliarme</Button>
                </div>
                <div className="social-login">
                    <h3 >Síguenos</h3>
                    <div className="social-icons">
                        <FacebookIcon  onClick={()=>{window.open("https://www.facebook.com/HotExpressPanama/", "_blank")}}/>
                        <InstagramIcon  onClick={()=>{window.open("https://www.instagram.com/hotexpresspanama/", "_blank")}}/>
                        <YouTubeIcon  onClick={()=>{window.open("https://www.youtube.com/channel/UCPSdMYC2fc20LBEom3uYtyg", "_blank")}}/>
                    </div>
                </div>
            </div>
            <div className="screen__background">
                <span className="screen__background__shape screen__background__shape4"></span>
                <span className="screen__background__shape screen__background__shape3"></span>
                <span className="screen__background__shape screen__background__shape2"></span>
                <span className="screen__background__shape screen__background__shape1"></span>
            </div>
        </div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Button className='closeBottom' onClick={handleClose}>X</Button>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Por favor registre su contraseña personal
            </Typography>
            <div style={{textAlign:'center'}}>
            <TextField id="standard-passnew" label="Nueva Contraseña" type ="password" variant="standard" value={newPass.passwordNew} onChange={(e)=>{handleNewPass(e,"passwordNew")}} />
            </div>
            <div style={{textAlign:'center'}}>
            <TextField id="standard-passnew2" label="Confirmar Contraseña" type ="password" variant="standard"  value={newPass.passwordNew2} onChange={(e)=>{handleNewPass(e,"passwordNew2")}} />
            </div>
            <div style={{padding:'0px 30px', display:'flex', justifyContent:'center'}}>
                <Button className='login__submit' onClick={()=>saveNewPassword()}>Registrar Contraseña</Button>
            </div>
            {statusNewPass?
                statusNewPass.Estatus === "false" && statusNewPass.Mensaje !== ""?
                    <Alert className='alerta' variant="outlined" severity="error">
                        {statusNewPass.Mensaje}
                    </Alert>
                :null:null}
        </Box>
        </Modal>
    </div>
    )
}

export default Login;