import React, { useState, useEffect }  from 'react'

import Box from '@mui/material/Box';

import '../css/home.css';
import Header from './Header';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ServicePackage from '../assets/servicePackage.png'
import Balance from '../assets/balance.png'
import Consumo from '../assets/consumption.png'
import Excedente from '../assets/surplus.png'
import {useHistory} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    borderRadius:'10px',
    minHeight:'60px',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  }));

const Home = ({dataUser})=>{
    let history = useHistory()
    const [scrollPosition, setScrollPosition] = useState(0);
    const [loading,SetLoading] = useState(true)
    if (dataUser) {
        const lastSesionTime = new Date()
        localStorage.setItem("dataUser", JSON.stringify(dataUser));
        localStorage.setItem("lastSesion", lastSesionTime);
    }else{
        function isMoreThan30Minutes(date1, date2) {
            const diff = Math.abs(date2 - date1);
            const minutesDiff = diff / (1000 * 60); 
                return minutesDiff > 30;
        }
        const lastSesion = localStorage.getItem('lastSesion');
        const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
        //console.log(dataUserLocal)
        if (lastSesion && dataUserLocal) {
            const now = new Date();
            if (isMoreThan30Minutes(new Date(lastSesion), now)) {
                localStorage.removeItem('dataUser');
                localStorage.removeItem('lastSesion');
                history.push("/")
            }
        }else{
            history.push("/")
        }
    }
    useEffect(() => {
    function handleScroll() {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);
    const [planInfo,SetPlanInfo] = useState({
        "PinNo": "HB-4954",
        "NombreCliente": "FERNANDO LOZA",
        "IdPlan": "2",
        "NombrePlan": "",
        "LibrasPlan": "0.000",
        "LibrasConsumidas": "0.000",
        "LibrasExcedentes": "0.000",
        "ValorExcedentes": "0.000"
    })
    const [guiasInfo,SetGuiasInfo] = useState(false)
    useEffect(() => {
        const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://rolando.weorderit.com/datasnap/rest/TApiHotExpress/InformacionPlan/${dataUserLocal.PinNo}`, requestOptions)
            .then(response => response.json())
            .then(result =>{
                console.log(result)
                const infoPlan = result.result[0].InfoPlan[0];
                console.log(infoPlan)
                SetPlanInfo(infoPlan)
            })
            .catch(error => console.log('error', error));

            var requestOptions2 = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`https://rolando.weorderit.com/datasnap/rest/TApiHotExpress/GuiasPendiente/${dataUserLocal.PinNo}`, requestOptions2)
                .then(response => response.json())
                .then(result => {
                    const infoGuias = result.result[0].Guias;
                    console.log(infoGuias)
                    SetGuiasInfo(infoGuias)
                    SetLoading(false)
                })
                .catch(error => console.log('error', error));
    },[])
    const findColor = (Estatus)=>{
        //Recibido Hot Miami, En Transito, Recibido en Tocumen, Recibido en Oficina, Recibido en Sucursal, En Ruta de Entrega y Entregado
        switch (Estatus) {
            case 'Recibido Hot Miami':
                return '#282A6F'
            case 'En Transito':
                return '#838383'
            case 'Recibido en Tocumen':
                return '#D96D38'
            case 'Recibido en Oficina':
                return '#D96D38'
            case 'Recibido en Sucursal':
                return '#D96D38'
            case 'En Ruta de Entrega y Entregado':
                return '#286f42'
            default:
                return '#838383'
        }
    }
    const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
    const isHBClient = (dataUserLocal.PinNo ?? '').toLowerCase().includes('hb') === true

    return(
        <div style={{position:'relative'}}>
            <Header history={history} dataUser={dataUserLocal}/>
            {isHBClient?
            <div className='infoUser topMargin'>
            <Box sx={{ width: { xs: '95%', sm: '80%' }}} >
            <Grid container spacing={2} alignItems="stretch" direction="row" justifyContent="center">
                    <Grid item xs={12} sm={6} md={3} lg={true} >
                        <Item sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%'}}>
                                {scrollPosition < 55?
                                <div className='circleIconMin'>
                                    <img alt='icon' src={ServicePackage} className="iconImage"/>
                                </div>
                                :planInfo.NombrePlan}
                                <div className='textItemBox'>{(+(planInfo.LibrasPlan))}<span style={{fontSize:'14px'}}>lbs</span></div>
                            </div>
                            <div className='titleBox' style={{borderTop:"3px solid #3478F6"}}>{scrollPosition > 55?null:`${planInfo.NombrePlan} / ${(+(planInfo.LibrasPlan))}lbs`}</div>
                        </Item>
                        <Item sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <div className='circleIcon'>
                                <img alt='icon'  src={ServicePackage} className="iconImage"/>
                            </div>
                            <div className='textItemBox'>{(+(planInfo.LibrasPlan))}<span style={{fontSize:'18px'}}>lbs</span></div>
                            <div className='titleBox' style={{borderTop:"3px solid #3478F6"}}>{planInfo.NombrePlan} / <span>{(+(planInfo.LibrasPlan))}</span><span style={{fontSize:'14px'}}>lbs</span></div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={true}>
                    <Item sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%'}}>
                                {scrollPosition < 55?
                                <div className='circleIconMin'>
                                    <img alt='icon' src={Balance} className="iconImage"/>
                                </div>
                                :'Balance'}
                                <div className='textItemBox'>{(+(planInfo.LibrasPlan)-+(planInfo.LibrasConsumidas))}<span style={{fontSize:'18px'}}>lbs</span></div>
                            </div>
                            <div className='titleBox' style={{borderTop:"3px solid #53A551"}}>{scrollPosition > 55?null:'Balance'}</div>
                        </Item>
                        <Item sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <div className='circleIcon'>
                                <img alt='icon'  src={Balance} className="iconImage"/>
                            </div>
                            <div className='textItemBox'>{(+(planInfo.LibrasPlan)-+(planInfo.LibrasConsumidas))}<span style={{fontSize:'18px'}}>lbs</span></div>
                            <div className='titleBox' style={{borderTop:"3px solid #53A551"}}>Balance</div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={true}>
                    <Item sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%'}}>
                                {scrollPosition < 55?
                                <div className='circleIconMin'>
                                    <img alt='icon' src={Consumo} className="iconImage"/>
                                </div>
                                :'Consumido'}
                                <div className='textItemBox'>{(+(planInfo.LibrasConsumidas))}<span style={{fontSize:'18px'}}>lbs</span></div>
                            </div>
                            <div className='titleBox' style={{borderTop:"3px solid #F5C445"}}>{scrollPosition > 55?null:'Consumido'}</div>
                        </Item>
                        <Item sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <div className='circleIcon'>
                                <img alt='icon'  src={Consumo} className="iconImage"/>
                            </div>
                            <div className='textItemBox'>{(+(planInfo.LibrasConsumidas))}<span style={{fontSize:'18px'}}>lbs</span></div>
                            <div className='titleBox' style={{borderTop:"3px solid #F5C445"}}>Consumido</div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={true}>
                    <Item sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%'}}>
                                {scrollPosition < 55?
                                <div className='circleIconMin'>
                                    <img alt='icon' src={Excedente} className="iconImage"/>
                                </div>
                                :'Excedente'}
                                <div className='textItemBox'>${(+(planInfo.ValorExcedentes)).toFixed(2)}</div>
                            </div>
                            <div className='titleBox' style={{borderTop:"3px solid #CB444B"}}>{scrollPosition > 55?null:`Excedente / `+(+(planInfo.LibrasExcedentes))+' lbs '}</div>
                        </Item>
                        <Item sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <div className='circleIcon'>
                                <img alt='icon'  src={Excedente} className="iconImage"/>
                            </div>
                            <div className='textItemBox'>${(+(planInfo.ValorExcedentes)).toFixed(2)}</div>
                            <div className='titleBox' style={{borderTop:"3px solid #CB444B"}}>Excedente / {(+(planInfo.LibrasExcedentes))}<span >lbs</span></div>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            </div>
            :null}
            <div className={`infoDetailPackage1 ${isHBClient?null:'topMargin'}`} >
                <Box sx={{ width: { xs: '95%', sm: '80%' }}}>
                    PAQUETES EN TRÁNSITO
                </Box>
            </div>
            <div className='infoUserList' style={{ background:'#F9F9F9'}}>
                <Box sx={{ width: { xs: '95%', sm: '80%' },display:'grid',gap:'10px' }}>
                {guiasInfo ?
                    guiasInfo.map((item,index)=>{
                        return(
                            <div className='itemList' key={index}>
                                <div className='leftColor' style={{borderRight:`3px solid ${findColor(item.Estado)}`}}></div>
                                <div className='itemListLeft'>
                                    <span style={{color:'#707070'}}>{item.Ciupa}</span>
                                    <span style={{color:'#A2A2A2'}}>{item.Fecha}</span>
                                    <span style={{color:'#707070'}}>Estado: <strong>{item.Estado}</strong></span>
                                </div>
                                <div className='infoPeso'>
                                    <span style={{color:'#707070'}}>Peso: <strong>{(+(item.Peso)).toFixed(2)}</strong>lbs</span>
                                    <span style={{color:'#8f8f8f', fontSize:'12px'}}> Remitente:</span>
                                    <span style={{color:'#707070', fontSize:'11px',textAlign:'right'}}><strong>{(item.Remitente)}</strong></span>
                                </div>
                            </div>
                        )
                    })
                    :
                    loading?
                        <>
                            <Skeleton sx={{ bgcolor: '#eaeaea' }} style={{borderRadius:'25px'}} animation="wave"  variant="rounded"  height={120} />
                            <Skeleton sx={{ bgcolor: '#eaeaea' }} style={{borderRadius:'25px'}} animation="wave"  variant="rounded"  height={120} />
                            <Skeleton sx={{ bgcolor: '#eaeaea' }} style={{borderRadius:'25px'}} animation="wave"  variant="rounded"  height={120} />
                        </>
                        :
                        <div>No hay guías disponibles</div>
                }
                </Box>
            </div>
        </div>
    )
}
export default Home;